<template>
	<el-select v-model="code" :size="size" filterable remote automatic-dropdown style="min-width: 200px;"
		:clearable="clearable" default-first-option :placeholder="$t('i18nn_a8980879d3a4c82a')" :remote-method="remoteMethod"
		@change="changeData" :loading="loading">
		<el-option v-for="(item, index) in list" :key="index" :label="item.address" :value="item.id">
			<div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.address }}</span>
				<span class="sel_option_code" style="">{{ item.code }}</span>
				<!-- <span class="sel_option_code" style="">{{ item.id }}</span> -->
				
			</div>
		</el-option>
	</el-select>
</template>
<script>
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			clearable: {
				default: function() {
					return true;
				},
				type: Boolean
			},
			size: {
				default: function() {
					return '';
				},
				type: String
			},
			// fileList: {
			//   default: function() {
			//     return [];
			//   },
			//   type: Array
			// }
		},
		components: {},
		data() {
			return {
				// dialogFile: false,
				// loadingUpload: false,

				code: '',
				loading: false,
				list: []
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// saveErr: false,

				// listUrl: [],

				// tableData: []
			};
		},
		// watch: {
		//   openTime: function(newVal, oldVal) {
		//     console.log('openTime');
		//     this.dialogFile = true;
		//     this.initData();
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			this.getListData('');
		},
		methods: {
			init(val) {
				if (val) {
					this.code = val;
				} else {
					this.code = '';
					this.getListData('');
				}
			},
			sel(val) {
				console.log('sel',val);
				// if (val) {
					
				// } else {
				// 	this.code = '';
				// 	this.getListData('');
				// }
				// this.changeData(val);
				this.getDataByCode(val);
			},
			changeData(data) {
				console.log("changeData", data);
				let emit_data = {};
				if (data) {
					emit_data = this.list.find(item => {
						return item.id == data;
					})
				}
				this.$emit('changeData', emit_data);
				//清空,重新搜索默认数据
				if (!data) {
					this.getListData('');
				}
			},
			clearData() {
				this.code = '';
				this.getListData('');
			},
			//客户模糊搜索
			remoteMethod(query) {
				// if (query !== '') {
				this.getListData(query);
				// } else {
				// 	this.cusList = [];
				// }
			},
			//模糊请求
			getListData(key) {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhTransferAddressPageList, {
						keyword: key.replace(/^\s+|\s+$/g, ""), //去除空格
						offset: 0,
						limit: 100
					})
					.then(({
						data
					}) => {
						console.log('名称搜索，请求成功');
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							this.list = data.rows;
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('搜索，请求失败');
						this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading = false;
					});
			},
			//查询单个
			getDataByCode(code) {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhTransferAddressPageList, {
						code: code, //去除空格
						offset: 0,
						limit: 100
					})
					.then(({
						data
					}) => {
						console.log('名称搜索，请求成功');
						console.log(data);
						
						this.loading = false;
						if (200 == data.code && data.rows.length==1) {
							// this.list = data.rows;
							this.code = data.rows[0].id;
							this.$emit('changeData', data.rows[0]);
						} else {
							// this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('搜索，请求失败');
						// this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading = false;
					});
			},
			getId() {
				return this.code;
			},
			//新增
			addData(formData) {
				this.postData(this.$urlConfig.WhTransferAddressAdd, formData, '', (data) => {
					console.log('addData');
					// this.$router.push({name:"TransportOutWh"});
					this.$emit('addSuccess',data)
				});
			},
			//删除
			delData(id) {
				this.postData(this.$urlConfig.WhTransferAddressDel+"/"+id, {}, 'delete', () => {
					console.log('delData');
					this.$message.success(this.$t('tips.successfullyDelete'));
					// this.$router.push({name:"TransportOutWh"});
					this.getListData(this.code);
					this.$emit('delSuccess');
				});
			},
			//提交信息
			postData(url, formData, type, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loading = false;
					if (200 == data.code) {
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 	type: 'success'
						// });

						callback(data);
						// }
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						// this.$message.warning(data.msg ? data.msg : this.$t('tips.submitError'));
						// this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						// 	type: 'warning'
						// 	//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// });
						callback(data);
					}
				}).catch(error => {
					console.log(error);
					// console.log(this.$t('tips.submitError'));
					this.loading = false;
					callback(error);
					// this.loading_load = false;
					// this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					// 	type: 'warning'
					// 	//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					// });
					// this.$message.warning(this.$t('tips.submitRequestError'));
				});
			},
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
